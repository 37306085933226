/**
 * some extra stuff related to botstrap layouts
 */


.container.trim {
    padding-left: 0;
    padding-right: 0;
}

.row.trim {
    margin-left: 0;
    margin-right: 0;
}

[class*="col-"] {
    &.trim {
        padding-left: 0;
        padding-right: 0;
    }
    &.trim-left {
        padding-left: 0;
    }
    &.trim-right {
        padding-right: 0;
    }
}

.container [class*="col-"] {
    &.center {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}


