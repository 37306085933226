
/*
** form styles
*/

.form-ctr {
	form {
		@include font-postal;
		color: $clr-grey-1;

		.error {
			font-size: rem-calc(18px);
			color: #fff;
			background-color: #e33;
			padding: 0.5rem 1rem;
			border-radius: 3px;
			margin-bottom: 1rem;
		}

        .fld-errors {
            margin: 0.25rem 0 1.5rem;
            color: #e33;
        }

		label {
			font-size: rem-calc(20px);
            &.fld-error {
                color: #e33;
            }
		}
		input[type="text"], input[type="email"] {
			font-size: rem-calc(18px);
			color: #333;
			padding: 0.2rem 0.5rem;
			width: 100%;
			max-width: 100%;
            border: 1px solid #ccc;
            &.fld-error {
                border-color: #e33;
            }
		}
		.form-grp {
			margin-bottom: 1rem;
		}
		.actions {
			margin: 0.5rem 0 0;
			input {
				font-size: rem-calc(18px);
			}
			> * {
				display: inline-block;
				margin-right: 1rem;
			}
		}
	}
}

/****************

input[type="text"].form-control, input[type="text"].custom {
    @include font-postal;
    font-size: rem-calc(15px);
    padding: 0.3em 0.5rem;
    color: #000;
    border: 1px solid #000;
    border-radius: 0 !important;
}

input[type="text"].custom.style2 {
    padding: 0.45rem 0.6rem;
    color: #000;
    background-color: #f2f2f2;
    border: none;
}

input[type="text"].custom.style3 {
    border: none;
}

textarea.form-control, textarea.custom {
    @include font-postal;
    font-size: rem-calc(15px);
    padding: 0.5rem;
    color: #000;
    border: 1px solid #000;
    border-radius: 0 !important;
}

.form-group {
    label {
        font-weight: normal;
    }
}

.checkbox {
    cursor: pointer;
}

*************/


