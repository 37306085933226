
/*
** buttons
*/


.btn {
	text-decoration: none !important;
	cursor: pointer;
	border: none !important;
	@include rounded(3px);
	text-transform: uppercase;
	display: inline-block;
	font-size: rem-calc(14px);
	padding: rem-calc(8px) rem-calc(10px) !important;
	&:disabled {
		cursor: default;
	}

	&.sml {
		font-size: rem-calc(11px);
		padding: rem-calc(5px) rem-calc(8px) !important;
	}
}


/*
** btn-black  white on #333
*/

.btn-black {
	@extend .btn;
	&, &:link, &:visited {
		color: #fff !important;
		background-color: #333 !important;
	}
	&:hover, &:active, &:not(:disabled) {
		background-color: darken(#333, 10%) !important;
	}
	&:disabled {
		background-color: lighten(#333, 10%) !important;
		pointer-events: none;
		cursor: default;
	}
}

.btn-black-sml {
	@extend .btn-black;
	@extend .sml;
}

