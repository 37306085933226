/**
 * this is the toplevel scss file for the alliance for forgotten australians site
 */
/**
 * first we import all the standard scss modules
 */
/**
 * some handy color routines
 */
/**
 * my own reset stuff
 */
/* line 6, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_reset.scss */
ul {
  list-style-type: none;
  padding-left: 0;
}

/**
 * some extra stuff related to botstrap layouts
 */
/* line 6, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_bootstrap_extra.scss */
.container.trim {
  padding-left: 0;
  padding-right: 0;
}

/* line 11, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_bootstrap_extra.scss */
.row.trim {
  margin-left: 0;
  margin-right: 0;
}

/* line 17, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_bootstrap_extra.scss */
[class*="col-"].trim {
  padding-left: 0;
  padding-right: 0;
}

/* line 21, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_bootstrap_extra.scss */
[class*="col-"].trim-left {
  padding-left: 0;
}

/* line 24, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_bootstrap_extra.scss */
[class*="col-"].trim-right {
  padding-right: 0;
}

/* line 30, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_bootstrap_extra.scss */
.container [class*="col-"].center {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

/**
 * stuff related to typography
 */
/* line 10, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_typography.scss */
html, body {
  font-size: 16px !important;
}

/**
 * STRIP UNIT
 * It strips the unit of measure and returns it
 */
/**
 * CONVERT TO REM
 */
/**
 * REM CALC 
 * Allows to optionally calculate on a different base value to counter compounding effect of rem's.
 * Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
 * 
 *   rem-calc(10 20 30px 40);
 * 
 * Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
 * 
 *   rem-calc((10, 20, 30, 40px));
 * 
 * Optionally call with a different base (eg: 8px) to calculate rem.
 * 
 *   rem-calc(16px 32px 48px, 8px);
 * 
 * If you require to comma separate your list
 * 
 *   rem-calc((16px, 32px, 48), 8px);
 */
/**
 * smooth fonts
 */
/**
 * all our fonts for the site
 */
/**
 * Beck
 */
@font-face {
  font-family: 'Beck';
  src: url("/fonts/Beck.eot");
  src: url("/fonts/Beck.eot") format("embedded-opentype"), url("/fonts/Beck.woff2") format("woff2"), url("/fonts/Beck.woff") format("woff"), url("/fonts/Beck.ttf") format("truetype"), url("/fonts/Beck.svg#Beck") format("svg");
}

/**
 * Postal
 */
@font-face {
  font-family: 'Postal';
  src: url("/fonts/Postal.eot");
  src: url("/fonts/Postal.eot") format("embedded-opentype"), url("/fonts/Postal.woff2") format("woff2"), url("/fonts/Postal.woff") format("woff"), url("/fonts/Postal.ttf") format("truetype"), url("/fonts/Postal.svg#Postal") format("svg");
}

/**
 * standard font mixins
 */
/**
 * Beck mixins
 */
/**
 * Postal mixins
 */
/*
** mixins
*/
/* see: https://css-tricks.com/centering-in-the-unknown/ */
/**
 * momentum scrolling on iOS
 */
/*
** colours
*/
/*
** buttons
*/
/* line 7, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_buttons.scss */
.btn, .btn-black, .btn-black-sml {
  text-decoration: none !important;
  cursor: pointer;
  border: none !important;
  border-radius: 3px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.5rem 0.625rem !important;
}

/* line 16, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_buttons.scss */
.btn:disabled, .btn-black:disabled, .btn-black-sml:disabled {
  cursor: default;
}

/* line 20, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_buttons.scss */
.btn.sml, .sml.btn-black, .btn-black-sml {
  font-size: 0.6875rem;
  padding: 0.3125rem 0.5rem !important;
}

/*
** btn-black  white on #333
*/
/* line 33, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_buttons.scss */
.btn-black, .btn-black-sml, .btn-black:link, .btn-black-sml:link, .btn-black:visited, .btn-black-sml:visited {
  color: #fff !important;
  background-color: #333 !important;
}

/* line 37, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_buttons.scss */
.btn-black:hover, .btn-black-sml:hover, .btn-black:active, .btn-black-sml:active, .btn-black:not(:disabled), .btn-black-sml:not(:disabled) {
  background-color: #1a1a1a !important;
}

/* line 40, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_buttons.scss */
.btn-black:disabled, .btn-black-sml:disabled {
  background-color: #4d4d4d !important;
  pointer-events: none;
  cursor: default;
}

/*
** a style for <a> links that have no text
*/
/* line 6, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.aimg {
  line-height: 0;
  font-size: 0;
  text-indent: -10000em;
}

/**
 * div's faking it as an <a>
 */
/* line 16, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.mimic-link {
  cursor: pointer;
}

/*
** dflt:  $clr-hero-yellow/none, $clr-hero-yellow/underline
*/
/* line 26, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
a, a:link, a:visited {
  color: #fff100 !important;
  text-decoration: none;
  cursor: pointer;
}

/* line 32, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
a:hover, a:active {
  color: #fff100 !important;
  text-decoration: underline;
}

/*
** astyle-noul  - no underline! useful for non-standard <a> links that
** are divs with all sorts of text in them...
*/
/* line 44, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle-noul, .astyle-noul:link, .astyle-noul:visited, .astyle-noul:hover, .astyle-noul:active {
  cursor: pointer;
  text-decoration: none !important;
}

/*
** astyle1  clr-hero-yellow/underline, clr-hero-yellow/underline
*/
/* line 55, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle1, .astyle1:link, .astyle1:visited {
  color: #fff100 !important;
  text-decoration: underline;
  cursor: pointer;
}

/* line 61, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle1:hover, .astyle1:active {
  color: #fff100 !important;
  text-decoration: underline;
}

/*
** astyle2  clr-hero-beige/underline, clr-hero-yellow/underline
*/
/* line 72, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle2, body#page-home #frame-about a, body#page-home #frame-venues a, .artwork-details #artwork-info #artwork-stuff #yada #where a, .astyle2:link, body#page-home #frame-about a:link, body#page-home #frame-venues a:link, .artwork-details #artwork-info #artwork-stuff #yada #where a:link, .astyle2:visited, body#page-home #frame-about a:visited, body#page-home #frame-venues a:visited, .artwork-details #artwork-info #artwork-stuff #yada #where a:visited {
  color: #c8c7b9 !important;
  text-decoration: underline;
  cursor: pointer;
}

/* line 78, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle2:hover, body#page-home #frame-about a:hover, body#page-home #frame-venues a:hover, .artwork-details #artwork-info #artwork-stuff #yada #where a:hover, .astyle2:active, body#page-home #frame-about a:active, body#page-home #frame-venues a:active, .artwork-details #artwork-info #artwork-stuff #yada #where a:active {
  color: #fff100 !important;
  text-decoration: underline;
}

/*
** astyle3  clr-hero-green/underline, clr-hero-beige/underline
*/
/* line 89, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle3, body#page-home #frame-supporters a, .astyle3:link, body#page-home #frame-supporters a:link, .astyle3:visited, body#page-home #frame-supporters a:visited {
  color: #395d65 !important;
  text-decoration: underline;
  cursor: pointer;
}

/* line 95, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle3:hover, body#page-home #frame-supporters a:hover, .astyle3:active, body#page-home #frame-supporters a:active {
  color: #c8c7b9 !important;
  text-decoration: underline;
}

/*
** astyle4  button-style, clr-hero-yellow on clr-hero-beige
*/
/* line 106, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle4, .bid-btn, .astyle4:link, .bid-btn:link, .astyle4:visited, .bid-btn:visited {
  color: #fff100 !important;
  background-color: #c8c7b9 !important;
  text-decoration: none !important;
  cursor: pointer;
  text-transform: uppercase !important;
  line-height: 1 !important;
}

/* line 115, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle4:hover, .bid-btn:hover, .astyle4:active, .bid-btn:active {
  text-decoration: none;
}

/*
** astyle5  clr-hero-green/none, clr-hero-green/underline
*/
/* line 125, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle5, .astyle5:link, .astyle5:visited {
  color: #395d65 !important;
  text-decoration: none;
  cursor: pointer;
}

/* line 131, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle5:hover, .astyle5:active {
  color: #395d65 !important;
  text-decoration: underline;
}

/*
** astyle6  clr-hero-beige/none, clr-hero-green/underline
*/
/* line 142, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle6, .astyle6:link, .astyle6:visited {
  color: #c8c7b9 !important;
  text-decoration: none;
  cursor: pointer;
}

/* line 148, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.astyle6:hover, .astyle6:active {
  color: #395d65 !important;
  text-decoration: underline;
}

/*
** "bid" button
*/
/* line 159, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_links.scss */
.bid-btn {
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 2.375rem !important;
  padding: 0.5rem 1.75rem 0.1rem !important;
  border-radius: 2px;
  border: none;
}

/* line 7, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic, .bx-wrapper .bx-controls-direction > *.bx-next:after, .bx-wrapper .bx-controls-direction > *.bx-prev:after {
  background: transparent url("/images/traceart-sprite._v8b2082d991.png") no-repeat scroll top left;
  background-size: 400px 200px;
  display: inline-block;
  /**
     * social media 
     * we try and make these icons about the same size, as some, like
     * the facebook icon, are pretty thin
     */
  /**
     * slideshow stuff
     */
  /**
     * dialogs
     */
}

/* line 20, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.twitter, .bx-wrapper .bx-controls-direction > .twitter.bx-next:after, .bx-wrapper .bx-controls-direction > .twitter.bx-prev:after {
  background-position: -50px -50px;
  width: 22px;
  height: 18px;
}

/* line 24, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.twitter:hover, .bx-wrapper .bx-controls-direction > .twitter.bx-next:hover:after, .bx-wrapper .bx-controls-direction > .twitter.bx-prev:hover:after {
  background-position: -100px -50px;
}

/* line 29, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.facebook, .bx-wrapper .bx-controls-direction > .facebook.bx-next:after, .bx-wrapper .bx-controls-direction > .facebook.bx-prev:after {
  background-position: -145px -50px;
  width: 20px;
  height: 19px;
}

/* line 36, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.facebook:hover, .bx-wrapper .bx-controls-direction > .facebook.bx-next:hover:after, .bx-wrapper .bx-controls-direction > .facebook.bx-prev:hover:after {
  background-position: -195px -50px;
}

/* line 42, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.instagram, .bx-wrapper .bx-controls-direction > .instagram.bx-next:after, .bx-wrapper .bx-controls-direction > .instagram.bx-prev:after {
  background-position: -250px -50px;
  width: 19px;
  height: 19px;
}

/* line 46, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.instagram:hover, .bx-wrapper .bx-controls-direction > .instagram.bx-next:hover:after, .bx-wrapper .bx-controls-direction > .instagram.bx-prev:hover:after {
  background-position: -300px -50px;
}

/* line 56, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.slideshow-arrow-prev, .bx-wrapper .bx-controls-direction > .slideshow-arrow-prev.bx-next:after, .bx-wrapper .bx-controls-direction > .bx-next.bx-prev:after, .bx-wrapper .bx-controls-direction > *.bx-prev:after {
  background-position: -49px -99px;
  width: 18px;
  height: 43px;
}

/* line 62, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.slideshow-arrow-next, .bx-wrapper .bx-controls-direction > *.bx-next:after, .bx-wrapper .bx-controls-direction > .slideshow-arrow-next.bx-prev:after, .bx-wrapper .bx-controls-direction > .bx-prev.bx-next:after {
  background-position: -99px -99px;
  width: 18px;
  height: 43px;
}

/* line 73, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.dlg-cross, .bx-wrapper .bx-controls-direction > .dlg-cross.bx-next:after, .bx-wrapper .bx-controls-direction > .dlg-cross.bx-prev:after {
  background-position: -148px -98px;
  width: 25px;
  height: 25px;
}

/* line 77, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.dlg-cross:hover, .bx-wrapper .bx-controls-direction > .dlg-cross.bx-next:hover:after, .bx-wrapper .bx-controls-direction > .dlg-cross.bx-prev:hover:after {
  background-position: -198px -98px;
}

/* line 82, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.dlg-cross-white, .bx-wrapper .bx-controls-direction > .dlg-cross-white.bx-next:after, .bx-wrapper .bx-controls-direction > .dlg-cross-white.bx-prev:after {
  background-position: -248px -98px;
  width: 25px;
  height: 25px;
}

/* line 86, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_graphics.scss */
.graphic.dlg-cross-white:hover, .bx-wrapper .bx-controls-direction > .dlg-cross-white.bx-next:hover:after, .bx-wrapper .bx-controls-direction > .dlg-cross-white.bx-prev:hover:after {
  background-position: -198px -98px;
}

/**
 * theme overrides for owl carousels
 * copy/paste/override from owl theme css file
 */
/* Styling Pagination*/
/* line 13, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_owl_carousel.scss */
.owl-theme .owl-controls .owl-page span {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  opacity: 1;
  border-radius: 20px;
  border: 2px solid #fff;
  background-color: #fff;
}

/* line 23, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_owl_carousel.scss */
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  background-color: #000;
}

/**
 * push the controls back over the carousel, and hide the buttons
 */
/* line 32, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_owl_carousel.scss */
.owl-controls {
  position: absolute;
  bottom: 5px;
  right: 10px;
  z-index: 10;
}

/* line 37, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_owl_carousel.scss */
.owl-controls .owl-buttons {
  display: none;
}

/*
** form styles
*/
/* line 7, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form {
  font-family: Postal;
  /* weight and style are part of the font */
  color: #8c8c8c;
}

/* line 11, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form .error {
  font-size: 1.125rem;
  color: #fff;
  background-color: #e33;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  margin-bottom: 1rem;
}

/* line 20, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form .fld-errors {
  margin: 0.25rem 0 1.5rem;
  color: #e33;
}

/* line 25, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form label {
  font-size: 1.25rem;
}

/* line 27, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form label.fld-error {
  color: #e33;
}

/* line 31, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form input[type="text"], .form-ctr form input[type="email"] {
  font-size: 1.125rem;
  color: #333;
  padding: 0.2rem 0.5rem;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
}

/* line 38, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form input[type="text"].fld-error, .form-ctr form input[type="email"].fld-error {
  border-color: #e33;
}

/* line 42, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form .form-grp {
  margin-bottom: 1rem;
}

/* line 45, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form .actions {
  margin: 0.5rem 0 0;
}

/* line 47, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form .actions input {
  font-size: 1.125rem;
}

/* line 50, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_forms.scss */
.form-ctr form .actions > * {
  display: inline-block;
  margin-right: 1rem;
}

/****************

input[type="text"].form-control, input[type="text"].custom {
    @include font-postal;
    font-size: rem-calc(15px);
    padding: 0.3em 0.5rem;
    color: #000;
    border: 1px solid #000;
    border-radius: 0 !important;
}

input[type="text"].custom.style2 {
    padding: 0.45rem 0.6rem;
    color: #000;
    background-color: #f2f2f2;
    border: none;
}

input[type="text"].custom.style3 {
    border: none;
}

textarea.form-control, textarea.custom {
    @include font-postal;
    font-size: rem-calc(15px);
    padding: 0.5rem;
    color: #000;
    border: 1px solid #000;
    border-radius: 0 !important;
}

.form-group {
    label {
        font-weight: normal;
    }
}

.checkbox {
    cursor: pointer;
}

*************/
/**
 * now we get down to the main scss stuff...mobile first, so we import
 * a base module, and then work our way upwards
 */
/**
 * BASE (MOBILE) SIZE
 * This are the mobile styles. It's what people see on their phones. If
 * you set a great foundation, you won't need to add too many styles in
 * the other stylesheets. Remember, keep it light: Speed is Important.
 */
/**
 * mobile first...
 */
/* line 5, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
html, body {
  width: 100%;
}

/* line 9, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
html {
  background-color: #fff;
  overflow-x: hidden;
}

/* line 14, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background-color: #fff;
  font-family: Postal;
  /* weight and style are part of the font */
}

/* line 18, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body.fixed {
  overflow: hidden;
}

/* line 25, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body.modal-open {
  overflow: hidden;
  position: fixed;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* line 50, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.container {
  max-width: 1000px;
}

/* line 55, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
h1 {
  margin-top: 40px;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/**
 * header/navbar
 */
/* line 68, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header {
  padding: 2rem 1rem;
  background-color: #395d65;
  position: relative;
}

/* line 72, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header h1 {
  text-align: center;
  color: #fff100;
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  padding: 1rem 0 0;
  margin: 0 0 1.5rem;
  text-transform: uppercase;
  line-height: 1;
}

/* line 83, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #logo-link {
  display: block;
  text-align: center;
  margin-bottom: 1rem;
}

/* line 87, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #logo-link > img {
  max-width: 90%;
  max-height: 220px;
}

/* line 92, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #main-nav {
  padding: 0.5rem 0 0.25rem;
  text-align: center;
}

/* line 95, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #main-nav ul {
  display: inline-block;
}

/* line 97, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #main-nav ul li {
  display: inline-block;
  margin-right: 0.75rem;
}

/* line 100, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #main-nav ul li:last-child {
  margin-right: 0;
}

/* line 104, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #main-nav ul a {
  line-height: 1;
  padding: 0.1rem 0.5rem 0.3rem;
  text-transform: lowercase;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1.125rem;
  color: #c8c7b9 !important;
}

/* line 111, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #main-nav ul a:hover, #main-header #main-nav ul a:active {
  color: #fff100 !important;
  text-decoration: none;
}

/* line 118, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #social-media {
  position: absolute;
  top: 10px;
  right: 20px;
}

/* line 122, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#main-header #social-media a {
  display: inline-block;
  margin-left: 5px;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*
** full screen dialogs
*/
/* line 138, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.fullscreen-dlg {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

/* line 146, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.fullscreen-dlg .inner {
  position: relative;
}

/* line 136, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_mixins.scss */
.fullscreen-dlg .inner .dlg-contents:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

/* line 150, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.fullscreen-dlg .inner .dlg-contents .close-ctr {
  cursor: pointer;
  position: absolute;
  z-index: 100001;
  top: 5px;
  right: 5px;
  opacity: 1;
}

/**
 *	slide show stylings
 */
/* line 168, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper {
  position: relative;
  border: none;
  box-shadow: none;
}

/* line 172, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bxslider {
  margin: 0;
  padding: 0;
}

/* line 175, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bxslider li {
  margin: 0;
  padding: 0;
}

/* line 178, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bxslider li .img-ctr {
  background-size: cover;
}

/* line 181, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bxslider li img {
  width: 100%;
}

/* line 184, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bxslider li .bx-caption {
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 0;
  padding: 8px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

/* line 192, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bxslider li .bx-caption > span {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 0.9375rem;
  line-height: 1.2;
  color: #fff;
}

/* line 201, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction {
  display: none;
  /****
		@media only screen and (max-width: 481px) {
			a {
				&.bx-next {
					top: 50%;
					right: -45px;
					margin-top: -50px;
					&:after {
						left: 18px;
					}
				}
				&.bx-prev {
					top: 50%;
					left: -45px;
					margin-top: -50px;
					&:after {
						right: 18px;
					}
				}
			}
		}
		****/
}

/* line 203, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction > * {
  text-indent: -99999px;
  width: 90px !important;
  height: 90px !important;
  position: absolute;
  cursor: pointer;
}

/* line 209, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction > *:before {
  content: '';
  background-color: transparent;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

/* line 223, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction > *.bx-next {
  top: 50%;
  right: 0;
  margin-top: -45px;
}

/* line 227, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction > *.bx-next:after {
  content: '<span></span>';
  display: block;
  position: absolute;
  top: 22px;
  left: 35px;
}

/* line 237, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction > *.bx-prev {
  top: 50%;
  left: 0;
  margin-top: -45px;
}

/* line 241, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.bx-wrapper .bx-controls-direction > *.bx-prev:after {
  content: '<span></span>';
  display: block;
  position: absolute;
  top: 22px;
  right: 35px;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/**
 * page: home
 */
/* line 136, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_mixins.scss */
body#page-home #frame-auction:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

/* line 291, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-auction a {
  float: left;
  width: 33.33333%;
  position: relative;
}

/* line 295, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-auction a img {
  width: 100%;
}

/* line 298, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-auction a .overlay {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff100;
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 2.5rem;
  color: #395d65;
  line-height: 1;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity .2s 0s linear;
}

/* line 314, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-auction a .overlay:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

/* line 317, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-auction a .overlay table, body#page-home #frame-auction a .overlay tr, body#page-home #frame-auction a .overlay td {
  width: 100%;
  height: 100%;
}

/* line 321, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-auction a .overlay td {
  vertical-align: middle;
}

/* line 328, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-testimonial {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #395d65;
}

/* line 332, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-testimonial .inner {
  border-top: 1px solid #c8c7b9;
  padding: 2rem 5% 0;
}

/* line 335, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-testimonial .inner .quote {
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 2.25rem;
  color: #c8c7b9;
  text-align: center;
  line-height: 1;
  margin-bottom: 0.5rem;
}

/* line 343, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-testimonial .inner .person {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1.375rem;
  color: #c8c7b9;
  text-align: center;
  line-height: 1;
}

/* line 353, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-about {
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #c8c7b9;
  background-color: #009ade;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
}

/* line 360, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-about .title {
  text-align: left;
  border-bottom: 1px solid #c8c7b9;
  padding-bottom: 2px;
  margin-bottom: 1.5rem;
  text-transform: lowercase;
}

/* line 367, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-about .team-contact {
  margin-top: 3rem;
}

/* line 373, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-about .logo-ctr {
  margin-top: 1.5rem;
}

/* line 375, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-about .logo-ctr a.cmty-plus img {
  height: 60px;
}

/* line 379, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-about p {
  margin-top: 1rem;
}

/* line 384, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr {
  margin: 0;
  height: 500px;
  background-color: #f0f0f0;
}

/* line 389, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box {
  background-color: #8c8c8c;
  border-radius: 2px;
  opacity: 1;
  filter: alpha(opacity=100);
  width: 450px;
  min-height: 100px;
  padding: 10px;
}

/* line 136, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_mixins.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

/* line 398, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .img-ctr {
  float: left;
}

/* line 400, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .img-ctr a {
  display: block;
  position: relative;
}

/* line 403, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .img-ctr a .gauze {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff100;
  opacity: 0.25;
  filter: alpha(opacity=25);
}

/* line 413, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .img-ctr a .thumb {
  float: left;
  width: 100px;
  height: auto;
}

/* line 419, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .img-ctr:hover .gauze {
  display: block;
}

/* line 423, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .info {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
  color: #c8c7b9;
  margin-left: 115px;
  line-height: 1.2;
}

/* line 429, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .info .venue {
  margin-bottom: 10px;
}

/* line 437, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box .main-ctr .info .courtesy {
  margin-top: 10px;
}

/* line 443, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box.multiple .thumbs {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c8c7b9;
}

/* line 136, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_mixins.scss */
body#page-home #map-ctr .infoBox .info-box.multiple .thumbs:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

/* line 448, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box.multiple .thumbs img {
  float: left;
  margin-right: 10px;
  width: 40px;
  border-radius: 2px;
  cursor: pointer;
}

/* line 456, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box.multiple .main-ctr {
  display: none;
}

/* line 458, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-ctr .infoBox .info-box.multiple .main-ctr:first-child {
  display: block;
}

/* line 467, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.97);
}

/* line 475, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .close-ctr {
  top: 12px;
  right: 12px;
}

/* line 479, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .thumbs {
  height: 60px;
  margin-bottom: 10px;
}

/* line 136, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_mixins.scss */
body#page-home #map-infobox-full .thumbs:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

/* line 483, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .thumbs .img-ctr {
  float: left;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-right: 2px;
  opacity: 0.5;
}

/* line 490, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .thumbs .img-ctr:hover, body#page-home #map-infobox-full .thumbs .img-ctr.sel-grp {
  opacity: 1;
}

/* line 493, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .thumbs .img-ctr img {
  width: 60px;
  height: 60px;
}

/* line 498, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .thumbs .sep {
  display: block;
  float: left;
  margin-left: 6px;
  margin-right: 8px;
  border-left: 1px solid #395d65;
  height: 60px;
}

/* line 507, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .artwork-ctr {
  padding: 2.5rem 2.5rem 2rem 2rem;
}

/* line 509, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #map-infobox-full .artwork-ctr .artwork-details {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

/* line 517, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-venues {
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #c8c7b9;
  background-color: #fff;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
}

/* line 524, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-venues .title {
  text-align: center;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
}

/* line 534, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-street-gallery {
  position: relative;
  cursor: pointer;
}

/* line 537, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-street-gallery > img {
  display: block;
  width: 100%;
}

/* line 541, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-street-gallery .caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 0.9375rem;
  line-height: 1.2;
  padding: 8px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

/* line 556, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters {
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: #395d65;
  background-color: #fff100;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
}

/* line 563, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .title {
  text-align: left;
  text-transform: lowercase;
  border-bottom: 1px solid #395d65;
  padding-bottom: 2px;
  margin-bottom: 1.5rem;
}

/* line 573, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .intro {
  display: none;
  text-align: center;
  padding-bottom: 1rem;
}

/* line 577, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .intro .supporter-text p {
  hyphens: none;
}

/* line 585, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .blah-text > div:last-child {
  margin-top: 2rem;
}

/* line 591, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .supporter-icons {
  margin-top: 4rem;
  text-align: center;
}

/* line 594, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .supporter-icons > a {
  display: inline-block;
  margin-left: 3.5rem;
}

/* line 597, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .supporter-icons > a:first-child {
  margin-left: 0;
}

/* line 601, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .supporter-icons > a.museum img {
  width: 120px;
}

/* line 606, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-home #frame-supporters .supporter-icons > a.bcc img {
  width: 120px;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/**
 * page: artwork
 */
/* line 626, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork #main-container {
  background-color: #fff;
}

/* line 630, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork #main-header {
  margin-bottom: 2rem;
}

/* line 634, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork .main.container {
  padding: 0 0 4rem;
}

/* line 643, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artist-info #artist-name {
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 2.5rem;
  color: #395d65;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 1rem;
}

/* line 651, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artist-info #artist-img-ctr {
  margin-bottom: 1.75rem;
}

/* line 653, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artist-info #artist-img-ctr img {
  border-radius: 3px;
  width: 100%;
}

/* line 658, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artist-info #artist-bio {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
  color: #395d65;
  line-height: 1.3;
}

/* line 664, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artist-info #artwork-blah {
  margin-top: 2.5rem;
  padding-top: 0.75rem;
  border-top: 1px solid #8c8c8c;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
  color: #8c8c8c;
}

/* line 674, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info {
  margin-top: 2rem;
}

/* line 677, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff > img {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
}

/* line 683, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #yada {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 0.875rem;
  color: #c8c7b9;
  line-height: 1.4;
}

/* line 689, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #yada #precis .courtesy {
  margin-top: 0.875rem;
}

/* line 699, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #map-view {
  display: block;
  margin: 1rem 0;
}

/* line 703, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #bidding {
  margin-top: 2rem;
}

/* line 706, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values {
  margin-top: 1.5rem;
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1rem;
  color: #8c8c8c;
}

/* line 711, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values .high {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* line 714, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values .high.not-yet {
  font-size: 1rem;
}

/* line 717, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values .high .value {
  font-weight: bold;
  color: #333;
}

/* line 722, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values .admin-actions {
  font-size: 0.9375rem;
}

/* line 724, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values .admin-actions .leadin {
  color: #53515a;
}

/* line 727, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
.artwork-details #artwork-info #artwork-stuff #values .admin-actions > a {
  display: block;
}

/**
 * fullscreen map for a single artwork
 */
/* line 740, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#map-fullscreen {
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  width: auto;
  height: auto;
  background-color: rgba(250, 250, 250, 0.97);
}

/* line 748, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#map-fullscreen .close-ctr {
  top: 12px;
  right: 12px;
}

/* line 753, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#map-fullscreen .map-ctr .inner {
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

/* line 759, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#map-fullscreen .map-ctr #map-info-header {
  padding: 0 0 1rem;
}

/* line 761, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#map-fullscreen .map-ctr #map-info-header .what {
  font-size: 1.125rem;
  color: #48464e;
}

/* line 765, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
#map-fullscreen .map-ctr #map-info-header .where {
  font-size: 1rem;
  color: #c8c7b9;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*
**	page: register bidder
*/
/* line 781, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register {
  font-family: Postal;
  /* weight and style are part of the font */
  color: #8c8c8c;
}

/* line 783, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register #main-container {
  background-color: #fff;
  padding-bottom: 10rem;
}

/* line 788, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register #main-header {
  margin-bottom: 2rem;
}

/* line 799, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register .page-title {
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 3rem;
  margin: 1rem 0;
  color: #333;
}

/* line 806, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register .leadin {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

/* line 811, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register .success {
  font-size: 1.125rem;
}

/* line 813, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-register .success .actions {
  margin-top: 2rem;
}

/*
**	page: bid on artwork
*/
/* line 826, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1.125rem;
  color: #8c8c8c;
}

/* line 828, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid #main-container {
  background-color: #fff;
  padding-bottom: 10rem;
}

/* line 833, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid #main-header {
  margin-bottom: 2rem;
}

/* line 837, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid .main.container {
  padding: 0 0 4rem;
}

/* line 850, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid #page-title {
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 3rem;
  color: #395d65;
  line-height: 1;
  margin: 0 0 1rem;
}

/* line 859, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid .high {
  margin-bottom: 1rem;
}

/* line 863, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid .register {
  margin-top: 0.5rem;
}

/* line 867, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid .success {
  font-size: 1.25rem;
}

/* line 869, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-artwork-bid .success .actions {
  margin-top: 2rem;
}

/*
**	page: all bids on artwork
*/
/* line 881, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids, body#page-all-bids-all-artworks, body#page-all-bidders {
  font-family: Postal;
  /* weight and style are part of the font */
  font-size: 1.125rem;
  color: #8c8c8c;
}

/* line 883, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids #main-header, body#page-all-bids-all-artworks #main-header, body#page-all-bidders #main-header {
  margin-bottom: 2rem;
}

/* line 887, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids .main.container, body#page-all-bids-all-artworks .main.container, body#page-all-bidders .main.container {
  padding: 0 0 4rem;
}

/* line 895, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids h1, body#page-all-bids-all-artworks h1, body#page-all-bidders h1 {
  font-family: Beck;
  /* weight and style are part of the font */
  font-size: 2.5rem;
  color: #395d65;
  line-height: 1;
  text-transform: uppercase;
  margin: 2.5rem 0 1rem;
}

/* line 902, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids h1:first-child, body#page-all-bids-all-artworks h1:first-child, body#page-all-bidders h1:first-child {
  margin-top: 0;
}

/* line 907, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids ul#bids, body#page-all-bids-all-artworks ul#bids, body#page-all-bidders ul#bids {
  font-family: "courier new", fixed;
  font-size: 1rem;
}

/* line 910, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_base.scss */
body#page-all-bids ul#bids li span, body#page-all-bids-all-artworks ul#bids li span, body#page-all-bidders ul#bids li span {
  display: inline-block;
  margin-left: 2rem;
}

/**
 * MOBILES IN LANDSCAPE MODE
 */
/**
 * LARGER MOBILE DEVICES
 * This is for devices like the Galaxy Note or something that's
 * larger than an iPhone but smaller than a tablet. Let's call them
 * tweeners.
 */
/**
 * TABLET & SMALLER LAPTOPS
 * This is the average viewing window. So Desktops, Laptops, and
 * in general anyone not viewing on a mobile device. Here's where
 * you can add resource intensive styles.
 */
@media only screen and (min-width: 768px) {
  /**
 * desktop styles
 */
  /**
 * header/navbar
 */
  /* line 10, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #main-header {
    padding: 1rem;
  }
  /* line 12, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #main-header h1 {
    font-size: 2rem;
    padding: 2rem 0 0;
    margin: 0 0 2rem;
  }
  /* line 17, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #main-header #main-nav {
    padding: 2rem 0 0.25rem;
  }
  /* line 20, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #main-header #social-media {
    top: 15px;
    right: 30px;
  }
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /* line 33, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #main-container > #content > #content-inner .container.trim {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /**
 *	slide show stylings
 */
  /* line 53, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  .bx-wrapper .bxslider li .bx-caption > span {
    font-size: 1.375rem;
    padding: 15px;
  }
  /* line 60, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  .bx-wrapper .bx-controls-direction {
    display: block;
  }
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /**
 * page: home
 */
  /* line 77, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-auction a {
    width: 20%;
  }
  /* line 82, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-testimonial {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  /* line 85, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-testimonial .inner {
    padding: 3rem 15% 0;
  }
  /* line 87, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-testimonial .inner .quote {
    font-size: 4.375rem;
  }
  /* line 90, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-testimonial .inner .person {
    font-size: 1.5rem;
  }
  /* line 96, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-about {
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 1.125rem;
  }
  /* line 102, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-venues {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.125rem;
  }
  /* line 109, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-street-gallery .caption {
    font-size: 1.375rem;
    padding: 15px;
  }
  /* line 115, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-supporters {
    padding-top: 6rem;
    padding-bottom: 6rem;
    font-size: 1.125rem;
  }
  /* line 121, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-home #frame-supporters .blah-text > div:last-child {
    margin-top: 0;
  }
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /**
 * page: artwork
 */
  /* line 139, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-artwork .main.container {
    padding: 3rem 0 8rem;
  }
  /* line 145, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  .artwork-details #artwork-info {
    margin-top: 0;
  }
  /* line 148, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  .artwork-details #artwork-info #artwork-stuff #bidding {
    margin-top: 5rem;
  }
  /**
 * fullscreen map for a single artwork
 */
  /* line 159, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #map-fullscreen {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
  }
  /* line 164, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #map-fullscreen .close-ctr {
    top: 12px;
    right: 12px;
  }
  /* line 169, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  #map-fullscreen .map-ctr .inner {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
  }
  /*
**	page: register bidder
*/
  /*
**	page: bid on artwork
*/
  /* line 198, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_768up.scss */
  body#page-artwork-bid .main.container {
    padding-top: 3rem;
    padding-bottom: 8rem;
  }
}

/**
 * DESKTOP
 * This is the average viewing window. So Desktops, Laptops, and
 * in general anyone not viewing on a mobile device. Here's where
 * you can add resource intensive styles.
 */
@media only screen and (min-width: 1024px) {
  /**
 * header/navbar
 */
  /* line 6, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_1024up.scss */
  #main-header #social-media {
    top: 30px;
    right: 30px;
  }
}

/**
 * RETINA (2x RESOLUTION DEVICES)
 * This applies to the retina iPhone (4s) and iPad (2,3) along with
 * other displays with a 2x resolution. You can also create a media
 * query for retina AND a certain size if you want. Go Nuts.
 */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  /* line 2, /Volumes/data/dropbox-bhu/Dropbox/devel/binarybhu/websites/traceart/traceart.dev/resources/sass/_2x.scss */
  .graphic, .bx-wrapper .bx-controls-direction > *.bx-next:after, .bx-wrapper .bx-controls-direction > *.bx-prev:after {
    background-image: url("/images/traceart-sprite@2x._v02f188d1fe.png");
  }
}

/**
 * PRINT STYLESHEET
 * Feel free to customize this. Remember to add things that won't make
 * sense to print at the bottom. Things like nav, ads, and forms should
 * be set to display none.
 */
