/**
 * my own reset stuff
 */


ul {
    list-style-type: none;
    padding-left: 0;
}

