
/*
** a style for <a> links that have no text
*/

.aimg {
	line-height: 0;
	font-size: 0;
	text-indent: -10000em;
}


/**
 * div's faking it as an <a>
 */
.mimic-link {
    cursor: pointer;
}



/*
** dflt:  $clr-hero-yellow/none, $clr-hero-yellow/underline
*/

a, a:link, a:visited {
	color: $clr-hero-yellow !important;
	text-decoration: none;
	cursor: pointer;
}

a:hover, a:active {
	color: $clr-hero-yellow !important;
	text-decoration: underline;
}



/*
** astyle-noul  - no underline! useful for non-standard <a> links that
** are divs with all sorts of text in them...
*/

.astyle-noul, .astyle-noul:link, .astyle-noul:visited, .astyle-noul:hover, .astyle-noul:active {
	cursor: pointer;
	text-decoration: none !important;
}



/*
** astyle1  clr-hero-yellow/underline, clr-hero-yellow/underline
*/

.astyle1, .astyle1:link, .astyle1:visited {
	color: $clr-hero-yellow !important;
	text-decoration: underline;
	cursor: pointer;
}

.astyle1:hover, .astyle1:active {
	color: $clr-hero-yellow !important;
	text-decoration: underline;
}



/*
** astyle2  clr-hero-beige/underline, clr-hero-yellow/underline
*/

.astyle2, .astyle2:link, .astyle2:visited {
	color: $clr-hero-beige !important;
	text-decoration: underline;
	cursor: pointer;
}

.astyle2:hover, .astyle2:active {
	color: $clr-hero-yellow !important;
	text-decoration: underline;
}



/*
** astyle3  clr-hero-green/underline, clr-hero-beige/underline
*/

.astyle3, .astyle3:link, .astyle3:visited {
	color: $clr-hero-green !important;
	text-decoration: underline;
	cursor: pointer;
}

.astyle3:hover, .astyle3:active {
	color: $clr-hero-beige !important;
	text-decoration: underline;
}



/*
** astyle4  button-style, clr-hero-yellow on clr-hero-beige
*/

.astyle4, .astyle4:link, .astyle4:visited {
	color: $clr-hero-yellow !important;
	background-color: $clr-hero-beige !important;
	text-decoration: none !important;
	cursor: pointer;
	text-transform: uppercase !important;
	line-height: 1 !important;
}

.astyle4:hover, .astyle4:active {
	text-decoration: none;
}



/*
** astyle5  clr-hero-green/none, clr-hero-green/underline
*/

.astyle5, .astyle5:link, .astyle5:visited {
	color: $clr-hero-green !important;
	text-decoration: none;
	cursor: pointer;
}

.astyle5:hover, .astyle5:active {
	color: $clr-hero-green !important;
	text-decoration: underline;
}



/*
** astyle6  clr-hero-beige/none, clr-hero-green/underline
*/

.astyle6, .astyle6:link, .astyle6:visited {
	color: $clr-hero-beige !important;
	text-decoration: none;
	cursor: pointer;
}

.astyle6:hover, .astyle6:active {
	color: $clr-hero-green !important;
	text-decoration: underline;
}



/*
** "bid" button
*/

.bid-btn {
	@extend .astyle4;
	@include font-beck;
	font-size: rem-calc(38px) !important;
	padding: 0.5rem 1.75rem 0.1rem !important;
	@include rounded(2px);
	border: none;
}


