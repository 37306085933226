
/**
 * some handy color routines
 */

@function tint($color, $percent) {
    @return mix($color, #fff, $percent);
}

@function shade($color, $percent) {
    @return mix($color, #000, $percent);
}

