/*
** colours
*/

$clr-hero-yellow:			#fff100;
$clr-hero-green:			#395d65;
$clr-hero-beige:			#c8c7b9;
$clr-hero-lt-blue:			#009ade;

$clr-grey-1:				#8c8c8c;
$clr-grey-2:				#bcbec0;
$clr-grey-3:				#d1d3d4;

$clr-med-grey:				#53515a;
$clr-dark-grey:				#48464e;

$clr-text:					#333;
$clr-text-light:			lighten($clr-text, 25%);
$clr-text-lighter:			lighten($clr-text, 50%);
$clr-backg-light:			#f0f0f0;
$clr-err:					#e33;
$clr-text-white:			#e7e7e7;
$clr-text-grey:				#919096;

$clr-input:					#393f4f;
$clr-input-placeholder:		lighten($clr-input, 25%);
$clr-input-backg:			#fff;



