/**
 * theme overrides for owl carousels
 * copy/paste/override from owl theme css file
 */


/* Styling Pagination*/

.owl-theme .owl-controls .owl-page {
    //position: relative;
}

.owl-theme .owl-controls .owl-page span {
	width: 12px;
	height: 12px;
	margin: 0 5px;
	opacity: 1;
	border-radius: 20px;
    border: 2px solid #fff;
	background-color: #fff;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
    background-color: #000;
}

/**
 * push the controls back over the carousel, and hide the buttons
 */

.owl-controls {
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 10;
    .owl-buttons {
        display: none;
    }
}

