

$sprite-wid:		400px;
$sprite-hgt:		200px;


.graphic {

	background: transparent url($sprite) no-repeat scroll top left;
	background-size: $sprite-wid $sprite-hgt;
	display: inline-block;


    /**
     * social media 
     * we try and make these icons about the same size, as some, like
     * the facebook icon, are pretty thin
     */

	&.twitter {
		background-position: -50px -50px;
		width: 22px;
		height: 18px;
		&:hover {
			background-position: -100px -50px;
		}
	}

	&.facebook {
		//background-position: -150px -50px;
		//width: 10px;
		//height: 19px;
		background-position: -145px -50px;
		width: 20px;
		height: 19px;
		&:hover {
			//background-position: -200px -50px;
			background-position: -195px -50px;
		}
	}

	&.instagram {
		background-position: -250px -50px;
		width: 19px;
		height: 19px;
		&:hover {
			background-position: -300px -50px;
		}
	}


    /**
     * slideshow stuff
     */

	&.slideshow-arrow-prev {
		background-position: -49px -99px;
		width: 18px;
		height: 43px;
	}
	
	&.slideshow-arrow-next {
		background-position: -99px -99px;
		width: 18px;
		height: 43px;
	}
	

    /**
     * dialogs
     */

	&.dlg-cross {
		background-position: -148px -98px;
		width: 25px;
		height: 25px;
		&:hover {
			background-position: -198px -98px;
		}
	}
	
	&.dlg-cross-white {
		background-position: -248px -98px;
		width: 25px;
		height: 25px;
		&:hover {
			background-position: -198px -98px;
		}
	}
	
}
