/**
 * this is the toplevel scss file for the alliance for forgotten australians site
 */


/**
 * first we import all the standard scss modules
 */

@import "functions";
@import "reset";
@import "bootstrap_extra";
@import "typography";
@import "images";
@import "fonts";
@import "mixins";
@import "colors";
@import "buttons";
@import "links";
@import "graphics";
@import "owl_carousel";
@import "forms";
//@import "lists";
//@import "notifications";
//@import "dialogs";
//@import "panels";




/**
 * now we get down to the main scss stuff...mobile first, so we import
 * a base module, and then work our way upwards
 */

/**
 * BASE (MOBILE) SIZE
 * This are the mobile styles. It's what people see on their phones. If
 * you set a great foundation, you won't need to add too many styles in
 * the other stylesheets. Remember, keep it light: Speed is Important.
 */

@import "base";


/**
 * MOBILES IN LANDSCAPE MODE
 */

@media only screen and (min-width: 480px) {
	@import "480up";
}



/**
 * LARGER MOBILE DEVICES
 * This is for devices like the Galaxy Note or something that's
 * larger than an iPhone but smaller than a tablet. Let's call them
 * tweeners.
 */

@media only screen and (min-width: 481px) {
	@import "481up";
}


/**
 * TABLET & SMALLER LAPTOPS
 * This is the average viewing window. So Desktops, Laptops, and
 * in general anyone not viewing on a mobile device. Here's where
 * you can add resource intensive styles.
 */

@media only screen and (min-width: 768px) {
	@import "768up";
}



/**
 * DESKTOP
 * This is the average viewing window. So Desktops, Laptops, and
 * in general anyone not viewing on a mobile device. Here's where
 * you can add resource intensive styles.
 */

@media only screen and (min-width: 1024px) {
	@import "1024up";
}



/**
 * RETINA (2x RESOLUTION DEVICES)
 * This applies to the retina iPhone (4s) and iPad (2,3) along with
 * other displays with a 2x resolution. You can also create a media
 * query for retina AND a certain size if you want. Go Nuts.
 */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (min--moz-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5) {
	@import "2x";
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px),
       only screen and (min--moz-device-pixel-ratio: 1.5) and (min-width: 768px),
       only screen and (min-device-pixel-ratio: 1.5) and (min-width: 768px) {
    @import "768up_2x";
}



/**
 * PRINT STYLESHEET
 * Feel free to customize this. Remember to add things that won't make
 * sense to print at the bottom. Things like nav, ads, and forms should
 * be set to display none.
 */

@media print {
	@import "print";
}





