/**
 * desktop styles
 */


/**
 * header/navbar
 */

#main-header {
	padding: 1rem;
	h1 {
		font-size: rem-calc(32px);
		padding: 2rem 0 0;
		margin: 0 0 2rem;
	}
	#main-nav {
		padding: 2rem 0 0.25rem;
	}
	#social-media {
		top: 15px;
		right: 30px;
	}
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


#main-container {
    > #content {
        > #content-inner {
            .container.trim {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/**
 *	slide show stylings
 */

.bx-wrapper {
	.bxslider {
		li {
			.bx-caption {
                > span {
                    font-size: rem-calc(22px);
                    padding: 15px;
                }
			}
		}
	}
	.bx-controls-direction {
		display: block;
	}
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/**
 * page: home
 */

body#page-home {

	#frame-auction {
		a {
			width: (100% / 5);
		}
	}

	#frame-testimonial {
		padding-top: 10rem;
		padding-bottom: 10rem;
		.inner {
			padding: 3rem 15% 0;
			.quote {
				font-size: rem-calc(70px);
			}
			.person {
				font-size: rem-calc(24px);
			}
		}
	}

	#frame-about {
		padding-top: 3rem;
		padding-bottom: 3rem;
		font-size: rem-calc(18px);
	}

	#frame-venues {
		padding-top: 2rem;
		padding-bottom: 2rem;
		font-size: rem-calc(18px);
	}

	#frame-street-gallery {
		.caption {
			font-size: rem-calc(22px);
			padding: 15px;
		}
	}

	#frame-supporters {
		padding-top: 6rem;
		padding-bottom: 6rem;
		font-size: rem-calc(18px);
		.blah-text {
			> div {
				&:last-child {
					margin-top: 0;
				}
			}
		}
	}
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/**
 * page: artwork
 */

body#page-artwork {
	.main.container {
		padding: 3rem 0 8rem;
	}
}

.artwork-details {
	#artwork-info {
		margin-top: 0;
		#artwork-stuff {
			#bidding {
				margin-top: 5rem;
			}
		}
	}
}


/**
 * fullscreen map for a single artwork
 */
#map-fullscreen {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    .close-ctr {
        top: 12px;
        right: 12px;
    }
    .map-ctr {
        .inner {
            padding-top: 1rem;
            padding-right: 2rem;
            padding-bottom: 1.5rem;
            padding-left: 2rem;
        }
    }
}


/*
**	page: register bidder
*/

body#page-register {
	.form-ctr {
		form {
			//@include grid-column($columns: 15, $collapse: true);
		}
	}
}



/*
**	page: bid on artwork
*/

body#page-artwork-bid {
	.main.container {
		padding-top: 3rem;
        padding-bottom: 8rem;
	}
	.form-ctr {
		form {
			//@include grid-column($columns: 15, $collapse: true);
		}
	}
}
