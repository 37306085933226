
/*
** mixins
*/


//
// box shadows

@mixin box-shadow1() {
	//-o-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	//-moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	//-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

@mixin box-shadow($hshadow: 1px, $vshadow: 1px, $blur: 0px, $spread: 0px, $clr: #ccc) {
	//-webkit-box-shadow: $hshadow $vshadow $blur $spread $clr;
	//-moz-box-shadow:    $hshadow $vshadow $blur $spread $clr;
	box-shadow:         $hshadow $vshadow $blur $spread $clr;
}




//
// rounded corners

@mixin rounded($radius: 3px) {
	//-moz-border-radius: $radius;
	//-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin rounded-cnr($vert, $horz, $radius: 3px) {
	//-moz-border-radius-#{$vert}#{$horz}: $radius;
	//-webkit-border-#{$vert}-#{$horz}-radius: $radius;
	border-#{$vert}-#{$horz}-radius: $radius;
}

@mixin rounded-top-left($radius: 3px) {
	@include rounded-cnr(top, left, $radius);
}

@mixin rounded-top-right($radius: 3px) {
	@include rounded-cnr(top, right, $radius);
}

@mixin rounded-bottom-left($radius: 3px) {
	@include rounded-cnr(bottom, left, $radius);
}

@mixin rounded-bottom-right($radius: 3px) {
	@include rounded-cnr(bottom, right, $radius);
}



//
// opacity

@mixin opacity($pct: 50) {
	opacity: $pct / 100;
	filter: alpha(opacity=$pct);
}



//
// flexbox

@mixin flexbox($prop, $val) {
	@if $prop == "display" {
		//display: -o-flex;
		//display: -ms-flex;
		//display: -moz-flex;
		//display: -webkit-flex;
		display: flex;
	} @else {
		//-o-#{$prop}: #{$val};
		//-moz-#{$prop}: #{$val};
		//-webkit-#{$prop}: #{$val};
		//-ms-#{$prop}: #{$val};
		#{$prop}: #{$val};
	}
}


//
// transform

@mixin transform($val) {
	//-o-transform: #{$val};
	//-moz-transform: #{$val};
	//-webkit-transform: #{$val};
	//-ms-transform: #{$val};
	transform: #{$val};
}


//
// input placeholder

@mixin input-placeholder($clr) {
	::-webkit-input-placeholder {
		color: $clr !important;
	}
	::-moz-placeholder {
		color: $clr !important;
	}
	:-moz-placeholder {
		color: $clr !important;
	}
	:-ms-input-placeholder {
		color: $clr !important;
	}
}



//
// prevent wrapping of text

@mixin oneline {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}



//
// clearfix - edges us towards unsemantic markup

@mixin clearfix() {
	&:after {
		content: ".";
		display: block;
		height: 0;
		visibility: hidden;
		clear: both;
	}
}



/* see: https://css-tricks.com/centering-in-the-unknown/ */

@mixin center-parent() {
    text-align: center;

    /* May want to do this if there is risk the container may be narrower than the element inside */
    white-space: nowrap;

    /* The ghost, nudged to maintain perfect centering */
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em; /* Adjusts for spacing */
    }
}

@mixin center-child() {
    display: inline-block;
    vertical-align: middle;
}

@mixin center-parent-2() {
    display: table;
    width: 100%;
}

@mixin center-child-2() {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

@mixin center-child-2-content-left() {
    text-align: left;
}

@mixin center-parent-3() {
    position: relative;
}

@mixin center-child-3() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin center-child-3-content-left() {
    // nada
}



/**
 * momentum scrolling on iOS
 */
@mixin momentum-scrolling() {
    -webkit-overflow-scrolling: touch;
}


