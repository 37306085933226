/**
 * mobile first...
 */

html, body {
    width: 100%;
}

html {
    background-color: #fff;
    overflow-x: hidden;
}

body {
    @include font-smoothing;
    color: $clr-text;
    background-color: #fff;
    &.fixed {
        overflow: hidden;
    }
    @include font-postal;
    //padding-bottom: 100px;
}

body.modal-open {
    //haha
    overflow: hidden;
    position: fixed;
    //position: absolute;
    //position: relative;
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

#main-container {
}


#content {
}


#content-inner {
    //padding-bottom: 50px;
}


.container {
    max-width: 1000px;
}


h1 {
    margin-top: 40px;
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/**
 * header/navbar
 */

#main-header {
	padding: 2rem 1rem;
	background-color: $clr-hero-green;
	position: relative;
	h1 {
		text-align: center;
		color: $clr-hero-yellow;
		@include font-beck;
		font-size: rem-calc(24px);
		letter-spacing: 0.05rem;
		padding: 1rem 0 0;
		margin: 0 0 1.5rem;
		text-transform: uppercase;
		line-height: 1;
	}
	#logo-link {
        display: block;
		text-align: center;
		margin-bottom: 1rem;
        > img {
            max-width: 90%;
            max-height: 220px;
        }
	}
	#main-nav {
		padding: 0.5rem 0 0.25rem;
		text-align: center;
		ul {
			display: inline-block;
			li {
				display: inline-block;
				margin-right: 0.75rem;
				&:last-child {
					margin-right: 0;
				}
			}
			a {
				line-height: 1;
				padding: 0.1rem 0.5rem 0.3rem;
				text-transform: lowercase;
				@include font-postal;
				font-size: rem-calc(18px);
				color: $clr-hero-beige !important;
				&:hover, &:active {
					color: $clr-hero-yellow !important;
					text-decoration: none;
				}
			}
		}
	}
	#social-media {
		position: absolute;
		top: 10px;
		right: 20px;
		a {
			display: inline-block;
			margin-left: 5px;
		}
	}
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/*
** full screen dialogs
*/

.fullscreen-dlg {
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	.inner {
		position: relative;
		.dlg-contents {
			@include clearfix;
			.close-ctr {
				cursor: pointer;
				position: absolute;
				z-index: 100001;
				top: 5px;
				right: 5px;
                opacity: 1; // override bootstrap
			}
		}
	}
}



/**
 *	slide show stylings
 */

.bx-wrapper {
	position: relative;
    border: none;
    box-shadow: none;
	.bxslider {
		margin: 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0;
			.img-ctr {
				background-size: cover;
			}
			img {
				width: 100%;
			}
			.bx-caption {
				position: absolute;
				text-align: center;
				left: 0;
				bottom: 0;
				padding: 8px;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.4);
                > span {
                    @include font-postal;
                    font-size: rem-calc(15px);
                    line-height: 1.2;
                    color: #fff;
                }
			}
		}
	}
	.bx-controls-direction {
		display: none;
		> * {
			text-indent: -99999px;
			width: 90px !important;
			height: 90px !important;
			position: absolute;
			cursor: pointer;
			&:before {
				content: '';
				//background: #60295E;
				//background-color: #231f20;
				//background-color: #58595b;
				background-color: transparent;
				opacity: 0.7;
				width: 100%;
				height: 100%;
				@include rounded(50%);
				position: absolute;
				top: 0;
				left: 0;
			}	
			&.bx-next {
				top: 50%;
				right: 0;
				margin-top: -45px;
				&:after {
					content: '<span></span>';
					display: block;
					@extend .graphic;	
					@extend .slideshow-arrow-next;	
					position: absolute;
					top: 22px;
					left: 35px;
				}
			}
			&.bx-prev {
				top: 50%;
				left: 0;
				margin-top: -45px;
				&:after {
					content: '<span></span>';
					display: block;
					@extend .graphic;
					@extend .slideshow-arrow-prev;
					position: absolute;
					top: 22px;
					right: 35px;
				}
			}
		}
		/****
		@media only screen and (max-width: 481px) {
			a {
				&.bx-next {
					top: 50%;
					right: -45px;
					margin-top: -50px;
					&:after {
						left: 18px;
					}
				}
				&.bx-prev {
					top: 50%;
					left: -45px;
					margin-top: -50px;
					&:after {
						right: 18px;
					}
				}
			}
		}
		****/
	}
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/**
 * page: home
 */

body#page-home {

	#frame-auction {
		@include clearfix;
		a {
			float: left;
			width: (100% / 3);
			position: relative;
			img {
				width: 100%;
			}
			.overlay {
				position: absolute;
				z-index: 2;
				cursor: pointer;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $clr-hero-yellow;
				@include font-beck;
				font-size: rem-calc(40px);
				color: $clr-hero-green;
				line-height: 1;
				text-align: center;
				@include opacity(0);
				transition: opacity .2s 0s linear;
				&:hover {
					@include opacity(60);
				}
				table, tr, td {
					width: 100%;
					height: 100%;
				}
				td {
					vertical-align: middle;
				}
			}
		}
	}

	#frame-testimonial {
		padding-top: 3rem;
		padding-bottom: 3rem;
		background-color: $clr-hero-green;
		.inner {
			border-top: 1px solid $clr-hero-beige;
			padding: 2rem 5% 0;
			.quote {
				@include font-beck;
				font-size: rem-calc(36px);
				color: $clr-hero-beige;
				text-align: center;
				line-height: 1;
				margin-bottom: 0.5rem;
			}
			.person {
				@include font-postal;
				font-size: rem-calc(22px);
				color: $clr-hero-beige;
				text-align: center;
				line-height: 1;
			}
		}
	}

	#frame-about {
		padding-top: 2rem;
        padding-bottom: 2rem;
		color: $clr-hero-beige;
		background-color: $clr-hero-lt-blue;
		@include font-postal;
		font-size: rem-calc(16px);
		.title {
			text-align: left;
            border-bottom: 1px solid $clr-hero-beige;
            padding-bottom: 2px;
			margin-bottom: 1.5rem;
            text-transform: lowercase;
		}
		.team-contact {
			margin-top: 3rem;
		}
		a {
			@extend .astyle2;
		}
        .logo-ctr {
            margin-top: 1.5rem;
            a.cmty-plus img {
                height: 60px;
            }
        }
        p {
            margin-top: 1rem;
        }
	}

	#map-ctr {
		margin: 0;
		height: 500px;
		background-color: #f0f0f0;
		.infoBox {
			.info-box {
				background-color: $clr-grey-1;
				@include rounded(2px);
				@include opacity(100);
				width: 450px;
				min-height: 100px;
				padding: 10px;
				.main-ctr {
					@include clearfix;
					.img-ctr {
						float: left;
						a {
							display: block;
							position: relative;
							.gauze {
								display: none;
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: $clr-hero-yellow;
								@include opacity(25);
							}
							.thumb {
								float: left;
								width: 100px;
								height: auto;
							}
						}
						&:hover .gauze {
							display: block;
						}
					}
					.info {
						@include font-postal;
						font-size: rem-calc(16px);
						color: $clr-hero-beige;
						margin-left: 115px;
						line-height: 1.2;
						.venue {
							margin-bottom: 10px;
						}
						.artist {
							.name {
								//text-decoration: underline;
							}
						}
						.courtesy {
							margin-top: 10px;
						}
					}
				}
				&.multiple {
					.thumbs {
						padding-bottom: 10px;
						margin-bottom: 10px;
						border-bottom: 1px solid $clr-hero-beige;
						@include clearfix;
						img {
							float: left;
							margin-right: 10px;
							width: 40px;
							@include rounded(2px);
							cursor: pointer;
						}
					}
					.main-ctr {
						display: none;
						&:first-child {
							display: block;
						}
					}
				}
			}
		}
	}

    #map-infobox-full {
        top: 30px;
        bottom: 30px;
        left: 30px;
        right: 30px;
        width: auto;
        height: auto;
        background-color: rgba(255, 255, 255, 0.97);
        .close-ctr {
            top: 12px;
            right: 12px;
        }
        .thumbs {
            @include clearfix;
            height: 60px;
            margin-bottom: 10px;
            .img-ctr {
                float: left;
                width: 60px;
                height: 60px;
                cursor: pointer;
                margin-right: 2px;
                opacity: 0.5;
                &:hover, &.sel-grp {
                    opacity: 1;
                }
                img {
                    width: 60px;
                    height: 60px;
                }
            }
            .sep {
                display: block;
                float: left;
                margin-left: 6px;
                margin-right: 8px;
                border-left: 1px solid $clr-hero-green;
                height: 60px;
            }
        }
        .artwork-ctr {
            padding: 2.5rem 2.5rem 2rem 2rem;
            .artwork-details {
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                @include momentum-scrolling;
            }
        }
    }

	#frame-venues {
		padding-top: 2rem;
		padding-bottom: 2rem;
		color: $clr-hero-beige;
		background-color: #fff;
		@include font-postal;
		font-size: rem-calc(16px);
		.title {
			text-align: center;
			margin-bottom: 0.5rem;
            text-transform: lowercase;
		}
		a {
			@extend .astyle2;
		}
	}

	#frame-street-gallery {
		position: relative;
		cursor: pointer;
		> img {
			display: block;
			width: 100%;
		}
		.caption {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			@include font-postal;
			font-size: rem-calc(15px);
			line-height: 1.2;
			padding: 8px;
			color: #fff;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	#frame-supporters {
		padding-top: 4rem;
		padding-bottom: 4rem;
		color: $clr-hero-green;
		background-color: $clr-hero-yellow;
		@include font-postal;
		font-size: rem-calc(16px);
		.title {
			text-align: left;
            text-transform: lowercase;
            border-bottom: 1px solid $clr-hero-green;
            padding-bottom: 2px;
			margin-bottom: 1.5rem;
		}
		a {
			@extend .astyle3;
		}
		.intro {
            display: none;
			text-align: center;
			padding-bottom: 1rem;
            .supporter-text p {
                hyphens: none;
            }
		}
		.blah-text {
			//padding-top: 1.5rem;
			//border-top: 1px solid $clr-hero-green;
			> div {
				&:last-child {
					margin-top: 2rem;
				}
			}
		}

        .supporter-icons {
            margin-top: 4rem;
            text-align: center;
            > a {
                display: inline-block;
                margin-left: 3.5rem;
                &:first-child {
                    margin-left: 0;
                }
                &.museum {
                    img {
                        width: 120px;
                    }
                }
                &.bcc {
                    img {
                        width: 120px;
                    }
                }
            }
        }
	}
}


/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/**
 * page: artwork
 */

body#page-artwork {

	#main-container {
		background-color: #fff;
	}

	#main-header {
		margin-bottom: 2rem;
	}

	.main.container {
		padding: 0 0 4rem;
	}

}


.artwork-details {
	#artist-info {
		#artist-name {
			@include font-beck;
			font-size: rem-calc(40px);
			color: $clr-hero-green;
			line-height: 1;
			text-transform: uppercase;
			margin: 0 0 1rem;
		}
		#artist-img-ctr {
			margin-bottom: 1.75rem;
			img {
				@include rounded(3px);
				width: 100%;
			}
		}
		#artist-bio {
			@include font-postal;
			font-size: rem-calc(16px);
			color: $clr-hero-green;
			line-height: 1.3;
		}
		#artwork-blah {
			margin-top: 2.5rem;
			padding-top: 0.75rem;
			border-top: 1px solid $clr-grey-1;
			@include font-postal;
			font-size: rem-calc(16px);
			color: $clr-grey-1;
		}
	}

	#artwork-info {
		margin-top: 2rem;
		#artwork-stuff {
			> img {
				display: block;
				margin-bottom: 1rem;
                width: 100%;
				//@include rounded(3px);
			}
			#yada {
				@include font-postal;
				font-size: rem-calc(14px);
				color: $clr-hero-beige;
				line-height: 1.4;
				#precis {
					.courtesy {
						margin-top: rem-calc(14px);
					}
				}
				#where {
					a {
						@extend .astyle2;
					}
				}
			}
            #map-view {
                display: block;
                margin: 1rem 0;
            }
			#bidding {
				margin-top: 2rem;
			}
			#values {
				margin-top: 1.5rem;
				@include font-postal;
				font-size: rem-calc(16px);
				color: $clr-grey-1;
				.high {
					margin-bottom: 1rem;
					font-size: rem-calc(20px);
					&.not-yet {
						font-size: rem-calc(16px);
					}
					.value {
						font-weight: bold;
						color: #333;
					}
				}
                .admin-actions {
                    font-size: rem-calc(15px);
                    .leadin {
                        color: $clr-med-grey;
                    }
                    > a {
                        display: block;
                    }
                }
			}
		}
	}
}


/**
 * fullscreen map for a single artwork
 */
#map-fullscreen {
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    width: auto;
    height: auto;
    background-color: rgba(250, 250, 250, 0.97);
    .close-ctr {
        top: 12px;
        right: 12px;
    }
    .map-ctr {
        .inner {
            padding-top: 0.5rem;
            padding-right: 2.5rem;
            padding-bottom: 1rem;
            padding-left: 1rem;
        }
        #map-info-header {
            padding: 0 0 1rem;
            .what {
                font-size: rem-calc(18px);
                color: $clr-dark-grey;
            }
            .where {
                font-size: rem-calc(16px);
                color: $clr-hero-beige;
            }
        }
    }
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */


/*
**	page: register bidder
*/

body#page-register {

	#main-container {
		background-color: #fff;
		padding-bottom: 10rem;
	}

	#main-header {
		margin-bottom: 2rem;
	}

	#content-inner {
		//@include grid-column($columns: 30, $collapse: false, $center: true);
	}

	@include font-postal;
	color: $clr-grey-1;

	.page-title {
		@include font-beck;
		font-size: rem-calc(48px);
		margin: 1rem 0;
		color: #333;
	}

	.leadin {
		font-size: rem-calc(18px);
		margin-bottom: 1rem;
	}

	.success {
		font-size: rem-calc(18px);
		.actions {
            margin-top: 2rem;
        }
	}

}



/*
**	page: bid on artwork
*/

body#page-artwork-bid {

	#main-container {
		background-color: #fff;
		padding-bottom: 10rem;
	}

	#main-header {
		margin-bottom: 2rem;
	}

	.main.container {
		padding: 0 0 4rem;
	}

	#content-inner {
		//@include grid-column($columns: 30, $collapse: false, $center: true);
	}


	@include font-postal;
	font-size: rem-calc(18px);
	color: $clr-grey-1;

	#page-title {
		@include font-beck;
		font-size: rem-calc(48px);
		color: $clr-hero-green;
		line-height: 1;
		//text-transform: uppercase;
		margin: 0 0 1rem;
	}

	.high {
		margin-bottom: 1rem;
	}

    .register {
        margin-top: 0.5rem;
    }

	.success {
		font-size: rem-calc(20px);
        .actions {
            margin-top: 2rem;
        }
    }
}



/*
**	page: all bids on artwork
*/

body#page-all-bids, body#page-all-bids-all-artworks, body#page-all-bidders {

	#main-header {
		margin-bottom: 2rem;
	}

	.main.container {
		padding: 0 0 4rem;
	}

	@include font-postal;
	font-size: rem-calc(18px);
	color: $clr-grey-1;

	h1 {
		@include font-beck;
		font-size: rem-calc(40px);
		color: $clr-hero-green;
		line-height: 1;
		text-transform: uppercase;
		margin: 2.5rem 0 1rem;
		&:first-child {
			margin-top: 0;
		}
	}

	ul#bids {
		font-family: "courier new", fixed;
		font-size: rem-calc(16px);
		li span {
			display: inline-block;
			margin-left: 2rem;
		}
	}
}


