
/**
 * all our fonts for the site
 */


/**
 * Beck
 */

@font-face {
    font-family: 'Beck';
    src: url('/fonts/Beck.eot');
    src: url('/fonts/Beck.eot') format('embedded-opentype'),
         url('/fonts/Beck.woff2') format('woff2'),
         url('/fonts/Beck.woff') format('woff'),
         url('/fonts/Beck.ttf') format('truetype'),
         url('/fonts/Beck.svg#Beck') format('svg');
}


/**
 * Postal
 */

@font-face {
    font-family: 'Postal';
    src: url('/fonts/Postal.eot');
    src: url('/fonts/Postal.eot') format('embedded-opentype'),
         url('/fonts/Postal.woff2') format('woff2'),
         url('/fonts/Postal.woff') format('woff'),
         url('/fonts/Postal.ttf') format('truetype'),
         url('/fonts/Postal.svg#Postal') format('svg');
}



/**
 * standard font mixins
 */

@mixin font-plain() {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

@mixin font-plain-bold() {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-style: normal;
}



/**
 * Beck mixins
 */

@mixin font-beck() {
	font-family: Beck;
	/* weight and style are part of the font */
}


/**
 * Postal mixins
 */

@mixin font-postal() {
	font-family: Postal;
	/* weight and style are part of the font */
}

